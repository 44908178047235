var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-input-group',{staticClass:"position-relative",class:_vm.classes.join(' '),style:(_vm.customStyle),attrs:{"size":_vm.size},scopedSlots:_vm._u([(_vm.close)?{key:"prepend",fn:function(){return [_c('b-button',{attrs:{"variant":"danger"},on:{"click":_vm.hide}},[_c('b-icon-x-circle')],1)]},proxy:true}:null,(_vm.geolocation)?{key:"append",fn:function(){return [_c('b-button',{staticClass:"d-flex align-items-center text-capitalize",attrs:{"variant":"success","disabled":_vm.loading},on:{"click":_vm.getCurrentLocation}},[(_vm.loading)?_c('b-spinner',{staticClass:"mr-2",attrs:{"small":""}}):_c('b-icon-geo',{staticClass:"mr-1"}),_vm._v("\n\t\t\t"+_vm._s(_vm.$t('locate me'))+"\n\t\t")],1)]},proxy:true}:null],null,true)},[_vm._v(" "),_c('b-form-input',{ref:"input",class:{
			'rounded-right': !_vm.geolocation
		},attrs:{"placeholder":_vm.placeholder},on:{"input":_vm.getPlaces}}),_vm._v(" "),_vm._v(" "),_c('b-list-group',{staticClass:"position-absolute text-left",style:({
			maxHeight: '232px',
			top: `${_vm.top[_vm.size]}px`,
			left: 0,
			right: 0,
			overflowY: 'auto',
			zIndex: 1
		})},_vm._l((_vm.locations),function(location,index){return _c('b-list-group-item',{key:index,staticClass:"d-flex align-items-center",attrs:{"href":"#"},on:{"click":function($event){return _vm.setDeliveryLocation({ placeId: location.place_id })}}},[(location)?_c('b-row',{staticClass:"align-items-center w-100",staticStyle:{"line-height":"1"}},[_c('b-col',{staticClass:"text-center",attrs:{"md":"2"}},[_c('b-icon-geo-alt',{attrs:{"height":"24","width":"24"}})],1),_vm._v(" "),_c('b-col',{attrs:{"md":"10"}},[_c('h5',{staticClass:"mb-1"},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(location.structured_formatting.main_text)+"\n\t\t\t\t\t")]),_vm._v(" "),_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(location.description))])])],1):_c('h5',{staticClass:"mb-0 text-capitalize"},[_vm._v("\n\t\t\t\t"+_vm._s(_vm.$t('noResultFound'))+"\n\t\t\t")])],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }