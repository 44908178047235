<!-- eslint-disable vue/multi-word-component-names -->
<template>
	<b-input-group :size="size" class="position-relative" :class="classes.join(' ')" :style="customStyle">
		<template v-if="close" #prepend>
			<b-button
				variant="danger"
				@click="hide"
			>
				<b-icon-x-circle />
			</b-button>
		</template>
		<b-form-input
			ref="input"
			:placeholder="placeholder"
			:class="{
				'rounded-right': !geolocation
			}"
			@input="getPlaces"
		/>
		<template v-if="geolocation" #append>
			<b-button
				variant="success"
				class="d-flex align-items-center text-capitalize"
				:disabled="loading"
				@click="getCurrentLocation"
			>
				<b-spinner v-if="loading" class="mr-2" small />
				<b-icon-geo v-else class="mr-1" />
				{{ $t('locate me') }}
			</b-button>
		</template>
		<b-list-group
			class="position-absolute text-left"
			:style="{
				maxHeight: '232px',
				top: `${top[size]}px`,
				left: 0,
				right: 0,
				overflowY: 'auto',
				zIndex: 1
			}"
		>
			<b-list-group-item
				v-for="(location, index) in locations"
				:key="index"
				href="#"
				class="d-flex align-items-center"
				@click="setDeliveryLocation({ placeId: location.place_id })"
			>
				<b-row v-if="location" class="align-items-center w-100" style="line-height: 1">
					<b-col md="2" class="text-center">
						<b-icon-geo-alt height="24" width="24" />
					</b-col>
					<b-col md="10">
						<h5 class="mb-1">
							{{ location.structured_formatting.main_text }}
						</h5>
						<small class="text-muted">{{ location.description }}</small>
					</b-col>
				</b-row>
				<h5 v-else class="mb-0 text-capitalize">
					{{ $t('noResultFound') }}
				</h5>
			</b-list-group-item>
		</b-list-group>
	</b-input-group>
</template>

<script>
	export default {
		props: {
			size: {
				type: String,
				default: 'sm'
			},
			classes: {
				type: Array,
				default: () => ([])
			},
			customStyle: {
				type: Object,
				default: () => ({})
			},
			close: {
				type: Boolean,
				default: false
			},
			placeholder: {
				type: String,
				default: 'Search location'
			},
			geolocation: {
				type: Boolean,
				default: true
			},
			save: {
				type: Boolean,
				default: false
			}
		},
		data () {
			return {
				top: {
					sm: 30,
					'': 37,
					lg: 47
				},
				loading: false,
				locations: [],
				timer: null,
				token: null
			}
		},
		beforeMount () {
			this.generateToken()
		},
		methods: {
			generateToken () {
				this.token = this.$uuid.v4()
			},
			getPlaces ($event) {
				clearTimeout(this.timer)
				this.timer = setTimeout(() => {
					if ($event) {
						this.loading = true
						this.$store.dispatch('getPlaces', {
							input: $event,
							sessiontoken: this.token
						}).then((response) => {
							if (response.status === 'OK') {
								this.locations = response.predictions
							} else {
								this.locations = ['']
							}

							this.loading = false
						}).catch((err) => {
							this.showErrors(err)
							this.loading = false
						})
					} else {
						this.locations = []
						this.generateToken()
					}
				}, 500)
			},
			getCurrentLocation () {
				this.$bvToast.hide()

				if (navigator.geolocation) {
					this.loading = true
					navigator.geolocation.getCurrentPosition((position) => {
						this.setDeliveryLocation({
							latLng: `${position.coords.latitude},${position.coords.longitude}`
						})
					}, (err) => {
						this.loading = false

						if (err.code === 1) {
							this.showToast(this.$t('toastMessage.locationAccess'), {
								title: this.$t('access required'),
								variant: 'danger'
							})
						}
					}, {
						maximumAge: 10000,
						timeout: 5000,
						enableHighAccuracy: true
					})
				} else {
					this.showToast(this.$t('toastMessage.geoNotSupported'), {
						title: this.$options.filters.capitalize(this.$t('unsupported')),
						variant: 'danger'
					})
				}
			},
			setDeliveryLocation (data) {
				this.loading = true
				this.$refs.input.$el.value = ''
				this.locations = []
				this.$store.dispatch(data.placeId ? 'getPlace' : 'getPlaceByGeocode', Object.assign({
					save: this.save
				}, {
					place_id: data.placeId || null,
					sessiontoken: data.placeId ? this.token : null,
					latlng: data.latLng || null
				})).then((response) => {
					if (response.status === 'OK') {
						this.$emit('location', response.location)

						if (this.save) {
							this.$store.commit('setLocation', response.location)
						}
					}
				}).catch((err) => {
					this.showErrors(err)
				}).finally(() => {
					this.loading = false
					this.hide()
				})

				if (data.placeId) {
					this.generateToken()
				}
			},
			hide () {
				this.$emit('hide')
			}
		}
	}
</script>
